import { LitElement, html } from 'lit-element';

export class Particle extends LitElement {
	age = 0;
	lifetime = 100;

	render() {
		return html`
			<div id="particle" class="particle"></div>
		`;
	}

	animate() {
		this.age = this.age + 1;
	}

	died() {
		return this.age >= this.lifetime;
	}
}
