import { LitElement, html, css } from 'lit-element';

class Particles extends LitElement {
	static get styles() {
		return css`
			:host {
				display: block;
				position: relative;
			}
		`;
	}

	render() {
		return html`
			<hedge-emitter id="emitter" particle="particle-bit">
				<slot></slot>
			</hedge-emitter>
		`;
	}

	firstUpdated() {
		const emitter = this.shadowRoot.getElementById('emitter');
		// emitter.emit(Math.random() * 500 + 500, 20);
	}
}

customElements.define('hedge-particles', Particles);
