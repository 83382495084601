import { LitElement, html, css } from 'lit-element';

class HBox extends LitElement {
	static get styles() {
		return css`
			:host {
				display: flex;
                flex-direction: row;
				justify-content: center;
				align-items: center;
			}
		`;
	}

	render() {
		return html`
            <slot></slot>
		`;
	}
}

customElements.define('hedge-hbox', HBox);
