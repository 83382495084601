import { LitElement, html, css } from "lit-element";
import { classMap } from "lit-html/directives/class-map";

class QuantitySelect extends LitElement {
	static get properties() {
		return {
			name: { type: String },
			value: { type: String },
			primary: { type: Boolean },
			secondary: { type: Boolean }
		};
	}

	static get styles() {
		return css`
			:host {
				display: block;
				position: relative;
				cursor: pointer;
			}

			.quantity {
				height: 52px;
				padding: 0 52px 0 40px;
				border: 1px solid;
				border-radius: 4px;
				color: #848fa8;
			}

			.quantity.primary {
				color: #15c550;
			}

			.quantity.secondary {
				color: #4b9cff;
			}

			.quantity > img {
				position: absolute;
				top: 24px;	
				right: 12px;
				pointer-events: none;
			}

			::slotted(select) {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				font-family: "Whitney", "Helvetica Neue", -apple-system,
					BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Ubuntu,
					sans-serif;
				font-size: 16px;
				text-indent: 30px;

				outline: none;
				background: none;
				-webkit-appearance: none;
				-moz-appearance: none;
				appearance: none;
				border: none;
				cursor: pointer;
				color: inherit;
			}

			@-moz-document url-prefix() {
				::slotted(select) {
					text-indent: 15px;
				}
			}
		`;
	}

	render() {
		const classNames = {
			quantity: true,
			primary: this.primary,
			secondary: this.secondary
		};

		// prettier-ignore
		return html`
			<div class=${classMap(classNames)}>
				<slot></slot>
				<img
					src="${this.secondary
						? "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAOBAMAAADKyLEKAAAAJ1BMVEUAAABVqv9MnP9Mnf9Pn/9MnP9Lnf9Nnf9Lnf9MnP9MnP9NnP9LnP8aacvIAAAADHRSTlMABuuhLfO0PK1D4XQkRmQiAAAAU0lEQVQI12NgbGeAA8ZOBgbuowVwvtgpAQYGm3C4dI4KkGSGKxA75AAkQQoQ0hAFCGmIArg0TAFMGqYAKo1QAJFGKIBIIxRMA0sjFJwBSyMUwKQBJKgWyLgDg6QAAAAASUVORK5CYII="
						: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAOBAMAAADKyLEKAAAAJ1BMVEUAAAAAx0kAx0kAx0kAx0kAx0kAx0kAx0kAx0kAx0kAx0kAx0kAx0mkOBn4AAAADHRSTlMABuuhLfO0PK1D4XQkRmQiAAAAU0lEQVQI12NgbGeAA8ZOBgbuowVwvtgpAQYGm3C4dI4KkGSGKxA75AAkQQoQ0hAFCGmIArg0TAFMGqYAKo1QAJFGKIBIIxRMA0sjFJwBSyMUwKQBJKgWyLgDg6QAAAAASUVORK5CYII="}"
					width="15"
					height="7"
				/>
			</div>
		`;
	}
}

customElements.define("hedge-quantity", QuantitySelect);
