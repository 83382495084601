import { LitElement, html, css } from 'lit-element';
import { getCountryCode, inEurope } from '~/locale';

class Price extends LitElement {
	static get properties() {
		return {
			gpb: { type: String },
			usd: { type: String },
		};
	}

	static get styles() {
		return css`
			:host {
				display: block;
			}

			.label {
				font-family: 'Whitney', 'Helvetica Neue', -apple-system,
					BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Ubuntu,
					sans-serif;
				font-size: 40px;
				font-weight: 100;
				color: #000;
				text-align: center;
				text-rendering: optimizeSpeed;
			}

			@media (min-width: 1008px) {
				.label {
					font-size: 50px;
				}
			}
		`;
	}

	render() {
		return html`
			<span id="label" class="label">
				<slot></slot>
			</span>
		`;
	}

	async firstUpdated() {
		const label = this.shadowRoot.getElementById('label');
		const country = await getCountryCode();

		if (country === 'GB') {
			label.innerHTML = this.gpb;
		} else if (country === 'US') {
			label.innerHTML = this.usd;
		}
	}
}

customElements.define('hedge-price', Price);
