import { LitElement, html, css } from 'lit-element';

class Starfield extends LitElement {
	static get styles() {
		return css`
			:host {
				display: block;
				position: relative;
			}
		`;
	}

	render() {
		return html`
			<hedge-emitter id="emitter" particle="particle-star">
				<slot></slot>
			</hedge-emitter>
		`;
	}

	firstUpdated() {
		const emitter = this.shadowRoot.getElementById('emitter');
		emitter.emit(Math.random() * 150, 30);
	}
}

customElements.define('hedge-starfield', Starfield);
