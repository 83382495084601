import { LitElement, html, css } from "lit-element";

class Modal extends LitElement {
	static get properties() {
		return {};
	}

	static get styles() {
		return css`
			:host {
				display: none;
				position: fixed;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				overflow: hidden;
				cursor: pointer;

				background-color: rgba(0, 0, 0, 0.5);
				will-change: opacity;
				opacity: 0.001;
				z-index: 1072;
			}

			:host(.visible) {
				display: flex;
				animation: fade-in-modal 200ms linear forwards;
			}

			.content {
				position: relative;
				display: flex;
				flex-direction: column;
				align-items: center;
				margin: auto;
				padding: 56px;
				width: 100%;
				max-width: 480px;

				cursor: default;
				border-radius: 5px;
				background-color: #fff;
				backface-visibility: hidden;

				transform: translate3d(0, 100px, 0);
				will-change: transform;
			}

			:host(.visible) .content {
				animation: present-modal-dialog 300ms
					cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
			}

			@keyframes present-modal-dialog {
				to {
					transform: translate3d(0, 0, 0);
				}
			}

			@keyframes fade-in-modal {
				to {
					opacity: 1;
				}
			}
		`;
	}

	constructor() {
		super();

		this.handleKeyDown = this.handleKeyDown.bind(this);
		this.handleClick = this.handleClick.bind(this);

		this.visible = false;
	}

	render() {
		return html`<section id="content" class="content">
			<slot></slot>
		</section>`;
	}

	connectedCallback() {
		super.connectedCallback();
		window.addEventListener("keydown", this.handleKeyDown);
	}

	firstUpdated() {
		this.addEventListener("click", this.handleClick);
	}

	disconnectedCallback() {
		window.removeEventListener("keydown", this.handleKeyDown);
		super.disconnectedCallback();
	}

	handleClick(event) {
		if (event.composedPath().shift() === this) {
			this.hide();
		}
	}

	handleKeyDown(event) {
		if (event.key === "Escape") {
			this.hide();
		}
	}

	show() {
		if (this.classList.contains("visible") === false) {
			this.classList.add("visible");
		}
	}

	hide() {
		if (this.classList.contains("visible")) {
			this.classList.remove("visible");
		}
	}
}

customElements.define("hedge-modal", Modal);
