import { LitElement, html, css } from "lit-element";
import { classMap } from "lit-html/directives/class-map";

class ButtonLink extends LitElement {
	static get properties() {
		return {
			href: { type: String },
			type: { type: String },
			name: { type: String },
			value: { type: String },
			primary: { type: Boolean },
			secondary: { type: Boolean },
			click: { type: String },
		};
	}

	static get styles() {
		return css`
			:host {
				display: block;
				cursor: pointer;
			}

			.button {
				display: block;
				padding: 0 40px;
				height: 52px;
				border: 1px solid;
				border-radius: 4px;

				font-family:
					"Whitney",
					"Helvetica Neue",
					-apple-system,
					BlinkMacSystemFont,
					Segoe UI,
					Roboto,
					Helvetica Neue,
					Ubuntu,
					sans-serif;
				font-size: 18px;
				color: #848fa8;
				line-height: 52px;
				text-decoration: none;
			}

			.primary {
				color: #15c550;
			}

			.secondary {
				color: #4b9cff;
			}

			::slotted(span:last-of-type) {
				font-size: 14px !important;
				text-decoration: none;
			}
		`;
	}

	render() {
		const classNames = {
			button: true,
			primary: this.primary,
			secondary: this.secondary,
		};

		return html`
			<a href="${this.href}" class="${classMap(classNames)}">
				<slot></slot>
			</a>
		`;
	}
}

customElements.define("hedge-button-link", ButtonLink);
