import { LitElement, html, css } from 'lit-element';

class Gradient extends LitElement {
	static get styles() {
		return css`
			:host {
				display: block;
				background-image: linear-gradient(#1b1d22 25%, #31333d);
			}
		`;
	}

	render() {
		return html`
			<slot></slot>
		`;
	}
}

customElements.define('hedge-gradient', Gradient);
