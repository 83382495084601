import once from 'lodash.once';
import memoize from 'lodash.memoize';

const getIpInfo = () => {
	return new Promise(async resolve => {
		const url = 'https://ipinfo.io/json?token=1c54127346483c';
		const response = await fetch(url);

		const ipinfo = await response.json();
		resolve(ipinfo);
	});
};

export const getCountryCode = once(() => {
	return new Promise(async resolve => {
		const { country } = await getIpInfo();
		resolve(country);
	});
});

export const inEurope = memoize(country => {
    return [
        'AT', 'BE', 'CY', 'EE',
        'FI', 'FR', 'DE', 'GR',
        'IE', 'IT', 'LV', 'LT',
        'LU', 'MT', 'NL', 'PT',
        'SK', 'SI', 'ES',
    ].includes(country);
});
