import { LitElement, html, css } from 'lit-element';

import raf from 'raf';
import remove from 'lodash.remove';

export class Emitter extends LitElement {
	container;
	particles = [];
	running = false;

	static get properties() {
		return {
			particle: { type: String },
		};
	}

	static get styles() {
		return css`
			:host {
				display: block;
				position: relative;
			}

			.container {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				overflow: hidden;
			}
		`;
	}

	render() {
		return html`
			<div id="container" class="container"></div>
			<slot></slot>
		`;
	}

	emit(interval = 250, maxParticleCount = 10) {
		if (this.particles.length < maxParticleCount) {
			this.addParticle();

			if (!this.running) {
				this.animate();
			}
		}

		this.emitParticleId = setTimeout(
			() => this.emit(interval, maxParticleCount),
			interval
		);
	}

	burst(numberOfParticles = 10) {
		for (let i = 0; i < numberOfParticles; i += 1) {
			this.addParticle();
		}
	}

	stop() {
		clearTimeout(this.emitParticleId);
		raf.cancel(this.animateId);

		this.removeAllParticles();
	}

	animate = () => {
		this.particles.forEach(particle => {
			particle.animate();

			if (particle.died()) {
				this.removeParticle(particle);
			}
		});

		this.running = true;
		this.animateId = raf(this.animate);
	};

	addParticle() {
		const container = this.shadowRoot.getElementById('container');
		const particle = document.createElement(this.particle);

		if (!container) {
			return;
		}

		this.particles.push(particle);
		container.appendChild(particle);
	}

	removeParticle = particle => {
		const container = this.shadowRoot.getElementById('container');
		container.removeChild(particle);

		remove(this.particles, particle);
	};

	removeAllParticles() {
		this.particles.forEach(this.removeParticle);
	}
}

customElements.define('hedge-emitter', Emitter);
