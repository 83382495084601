import { LitElement, html, css } from "lit-element";
import { classMap } from "lit-html/directives/class-map";

class Switch extends LitElement {
	static get properties() {
		return {
			checked: { type: Boolean }
		};
	}

	constructor() {
		super();
		this.checked = false;
	}

	static get styles() {
		return css`
			:host {
				display: block;
			}

			.switch {
				position: relative;
				width: 44px;
				height: 24px;
				background-color: #eceef3;
				border-radius: 12px;
				cursor: pointer;
			}

			.slider {
				display: block;
				position: absolute;
				top: 3px;
				left: 3px;
				width: 18px;
				height: 18px;
				background-color: #4b9cff;
				border-radius: 12px;
				transition: all 125ms;
			}

			.switch.checked .slider {
				left: 22px;
			}
		`;
	}

	handleClick() {
		this.checked = !this.checked;

		const event = new CustomEvent("update", { bubbles: true, composed: true });
		this.dispatchEvent(event);
	}

	render() {
		return html`
			<div class="switch ${classMap({ checked: this.checked })}" @click="${this.handleClick}">
				<i class="slider"></i>
			</div>
		`;
	}
}

customElements.define("hedge-switch", Switch);
