import { Particle } from '~/components/particles/particle';
import { css } from 'lit-element';

import sample from 'lodash.sample';
import nth from 'lodash.nth';

class BitParticle extends Particle {
	static get styles() {
		return css`
			:host {
				display: block;
			}

			.particle {
				position: absolute;
				width: 1px;
				height: 1px;
				animation: fade-in-out 4s linear;
			}

			.particle.blue {
				background-color: #4b9cff;
			}

			.particle.green {
				background-color: #15c550;
			}

			.particle.white {
				background-color: #fff;
			}

			.particle:after {
				position: absolute;
				top: 0;
				right: 1px;
				height: 1px;
				content: '';
			}

			.particle:after {
				width: 150px;
				background-image: linear-gradient(
					to right,
					rgba(255, 255, 255, 0),
					rgba(255, 255, 255, 0.05)
				);
			}

			.particle.normal:after {
				width: 225px;
				background-image: linear-gradient(
					to right,
					rgba(255, 255, 255, 0),
					rgba(255, 255, 255, 0.07)
				);
			}

			.particle.big::after {
				width: 300px;
				background-image: linear-gradient(
					to right,
					rgba(255, 255, 255, 0),
					rgba(255, 255, 255, 0.09)
				);
			}

			@keyframes fade-in-out {
				from {
					opacity: 0;
				}

				25% {
					opacity: 1;
				}

				to {
					opacity: 0;
				}
			}
		`;
	}

	constructor() {
		super();

		this.lifetime = 240;
		this.depth = parseInt(Math.random() * 3, 10);
		this.translation = 0;
		this.speed = (this.depth + 1) * 1.5 + Math.random() * 2;
	}

	firstUpdated() {
		super.firstUpdated();

		const particle = this.shadowRoot.getElementById('particle');
		const color = sample(['blue', 'green', 'white']);
		const size = nth(['small', 'normal', 'big'], this.depth);
		const top = (Math.random() * 0.7 + 0.15) * 100;
		const left = Math.random() * 75;

		particle.className = `particle ${color} ${size}`;
		particle.style.cssText = `left: ${left}%; top: ${top}%`;
	}

	animate() {
		super.animate();

		const particle = this.shadowRoot.getElementById('particle');
		this.translation += this.speed;

		particle.style.WebkitTransform = `translate3d(${this.translation}px, 0, 0)`;
		particle.style.msTransform = `translate3d(${this.translation}px, 0, 0)`;
		particle.style.transform = `translate3d(${this.translation}px, 0, 0)`;
	}
}

customElements.define('particle-bit', BitParticle);
