import { LitElement, html, css } from 'lit-element';

class VBox extends LitElement {
	static get styles() {
		return css`
			:host {
				display: flex;
                flex-direction: column;
				justify-content: center;
				align-items: center;
			}
		`;
	}

	render() {
		return html`
            <slot></slot>
		`;
	}
}

customElements.define('hedge-vbox', VBox);
