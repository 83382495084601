import url from "url";
import { LitElement, html, css } from "lit-element";

class Notification extends LitElement {
	static get properties() {
		return {
			ref: { type: String },
		};
	}

	static get styles() {
		return css`
			:host {
				position: relative;
				order: -1;
			}

			.notification {
				display: none;
				padding: 40px 75px;
				font-family: "Whitney", "Helvetica Neue", -apple-system,
					BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Ubuntu,
					sans-serif;
				font-size: 17px;
				color: #8891a7;
				text-align: center;
				text-rendering: optimizeSpeed;
				background-color: white;
			}

			::slotted(a) {
				color: #4b9cff;
				text-decoration: none;
			}

			::slotted(a:hover) {
				color: #000;
			}
		`;
	}

	render() {
		return html`
			<aside id="notification" class="notification">
				<slot></slot>
			</aside>
		`;
	}

	firstUpdated() {
		const { query } = url.parse(document.location.href, true);
		if (this.ref === query.ref) {
			const notification = this.shadowRoot.getElementById("notification");
			notification.style.display = "block";
		}
	}
}

customElements.define("hedge-notification", Notification);
