import { Particle } from '~/components/particles/particle';
import { css } from 'lit-element';

import sample from 'lodash.sample';
import nth from 'lodash.nth';

class StarParticle extends Particle {
	static get styles() {
		return css`
			:host {
				display: block;
			}

			.particle {
				position: absolute;
				width: 1px;
				height: 1px;
				animation: fade-in-out 4s linear;
			}

			.particle.blue {
				background-color: #4b9cff;
			}

			.particle.green {
				background-color: #15c550;
			}

			.particle.white {
				background-color: #fff;
			}

			@keyframes fade-in-out {
				from {
					opacity: 0;
				}

				50% {
					opacity: 1;
				}

				to {
					opacity: 0;
				}
			}
		`;
	}

	constructor() {
		super();
		this.lifetime = 120;
	}

	firstUpdated() {
		super.firstUpdated();

		const particle = this.shadowRoot.getElementById('particle');

		const color = sample(['blue', 'green', 'white']);
		const top = Math.random() * 100;
		const left = Math.random() * 100;

		particle.className = `particle ${color}`;
		particle.style.cssText = `left: ${left}%; top: ${top}%`;
	}
}

customElements.define('particle-star', StarParticle);
